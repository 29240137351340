import React from "react";
import {
  FooterContainer,
  FooterLink,
  FooterLinkItems,
  FooterLinksContainer,
  FooterLinksWrapper,
  FooterLinkTitle,
  FooterWrap,
  FooterLine,
  FooterATag,
} from "./FooterElements";

const Footer = () => {
  return (
    <FooterContainer>
      <FooterWrap>
        <FooterLinksContainer>
          <FooterLinksWrapper>
            <FooterLinkItems>
              <FooterLinkTitle>Contact Us</FooterLinkTitle>
              <FooterLine>
                <strong>Main Office: </strong>3615 W. Boynton Beach Blvd.
                Boynton Beach, Florida 33436
              </FooterLine>
              <FooterLine></FooterLine>
              <FooterLine></FooterLine>
              <FooterLine>
                <strong>Satellite Office: </strong>7900 Glades Road, Suite 330,
                Boca Raton, Florida 33433
              </FooterLine>
              <FooterLine></FooterLine>
              <FooterLine></FooterLine>
              <FooterLine>
                <strong>Phone Number: </strong>(561) 740-3301
              </FooterLine>
              <FooterLine></FooterLine>
              <FooterLine></FooterLine>
              <FooterLine>
                <strong>E-mail: </strong>office@mmcohenlaw.com
              </FooterLine>
            </FooterLinkItems>

            <FooterLinkItems>
              <FooterLinkTitle>Areas of Practice</FooterLinkTitle>
              <FooterLine>{`Family & Matrimonial Law`}</FooterLine>
              <FooterLine></FooterLine>
              <FooterLine></FooterLine>
              <FooterLine>Civil Litigation</FooterLine>
              <FooterLine></FooterLine>
              <FooterLine></FooterLine>
              <FooterLine>Mediation</FooterLine>
            </FooterLinkItems>

            <FooterLinkItems>
              <FooterLinkTitle>Social Media</FooterLinkTitle>
              <FooterLine>
                <FooterATag
                  href="https://www.linkedin.com/in/marc-cohen-131bb111/"
                  target="_blank"
                >
                  LinkedIn
                </FooterATag>
              </FooterLine>
            </FooterLinkItems>
          </FooterLinksWrapper>
        </FooterLinksContainer>
      </FooterWrap>
    </FooterContainer>
  );
};

export default Footer;
