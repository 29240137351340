export const homeObjOne = {
  id: "our-mission",
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: "Premium Experience",
  headline: "Over 30 years of experience in Family & Matrimonial Law",
  description:
    "We currently have a 95% success rate for resolving matters in mediation, saving our clients thousands of dollars and many hours of stress.",
  buttonLabel: "Contact Us",
  imgStart: false,
  img: require("../../images/svg-2.svg").default,
  alt: "Mediation",
  dark: false,
  primary: false,
  darkText: true,
};

export const homeObjTwo = {
  id: "discover",
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: "Areas of Practice",
  headline: "We specialize in Family Law, Civil Litigation, and Mediation",
  description:
    "We have been selected as a Legal Aid Family Approved Lawyer for the last 15 years. The opposing side will fight for what they want. We will help you fight for a fair resolution that protects your interests.",
  buttonLabel: "Contact",
  imgStart: true,
  img: require("../../images/svg-4.svg").default,
  alt: "family",
  dark: false,
  primary: false,
  darkText: true,
};

export const homeObjThree = {
  id: "about",
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: "About",
  headline: "Marc M. Cohen Attorney at Law",
  description:
    "Marc Cohen is an attorney licensed in New York in 1994 and Florida in 2001. Mr. Cohen earned his J.D. from Brooklyn Law School and his MBA in Quantitative Analysis from St John’s University. Mr. Cohen is a member of the South Palm Beach County Bar Association and is a Supreme Court Certified Family Law Mediator.",
  buttonLabel: "Schedule a Free Consultation",
  imgStart: false,
  img: require("../../images/svg-8.png").default,
  alt: "Mediation",
  dark: false,
  primary: false,
  darkText: true,
};
