import React, { useState } from "react";
import emailjs from "emailjs-com";
import createHistory from "history/createBrowserHistory";

import {
  Container,
  FormWrap,
  Icon,
  FormContent,
  Form,
  FormH1,
  FormLabel,
  FormInput,
  FormButton,
} from "./ContactElements";

const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState();
  const [message, setMessage] = useState();

  // const clearForm = () => {
  //   setFirstName("");
  //   setLastName("");
  //   setEmail("");
  //   setNumber("");
  // };

  function sendEmail(e) {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_ualptsv",
        "contact_form",
        e.target,
        "user_nFX3g8PtetSA42zkhE0Al",
        name,
        email,
        number,
        message
      )
      .then((result) => {
        console.log(result.text);

        // window.location.reload();
        const history = createHistory();
        history.go(0);
      });
  }

  return (
    <>
      <Container>
        <FormWrap id="form">
          <Icon to="/">Marc M. Cohen P.A.</Icon>
          <FormContent>
            <Form action="#" onSubmit={sendEmail}>
              <FormH1>We look forward to getting in touch with you!</FormH1>
              <FormLabel htmlFor="for">Name</FormLabel>
              <FormInput
                type="text"
                required
                name="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                onBlur={(e) => setName(e.target.value)}
              />
              <FormLabel htmlFor="for">E-mail</FormLabel>
              <FormInput
                type="email"
                required
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                onBlur={(e) => setEmail(e.target.value)}
              />
              <FormLabel htmlFor="for">Phone Number</FormLabel>
              <FormInput
                type="tel"
                required
                name="number"
                value={number}
                onChange={(e) => setNumber(e.target.value)}
                onBlur={(e) => setNumber(e.target.value)}
              />
              <FormLabel htmlFor="for">Message</FormLabel>
              <FormInput
                type="text"
                required
                name="message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                onBlur={(e) => setMessage(e.target.value)}
              />
              <FormButton>Submit</FormButton>
            </Form>
          </FormContent>
        </FormWrap>
      </Container>
    </>
  );
};

export default Contact;
