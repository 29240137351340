import React from "react";
import Icon1 from "../../images/svg-9.svg";
import Icon2 from "../../images/svg-1.svg";
import Icon3 from "../../images/svg-4.svg";
import {
  ServicesContainer,
  ServicesH1,
  ServicesWrapper,
  ServicesCard,
  ServicesIcon,
  ServicesH2,
  ServicesP,
} from "./ServicesElements";
const Services = () => {
  return (
    <ServicesContainer id="services">
      <ServicesH1>Areas of Practice</ServicesH1>
      <ServicesWrapper>
        <ServicesCard>
          <ServicesIcon src={Icon1} />
          <ServicesH2>Family Law</ServicesH2>
          <ServicesP>
            We have been selected as a Legal Aid Family Approved Lawyer for the
            last 15 years.
          </ServicesP>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={Icon2} />
          <ServicesH2>Civil Litigation</ServicesH2>
          <ServicesP>
            The opposing side will fight for what they want. We will help you
            fight for a fair resolution that protects your interests.
          </ServicesP>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={Icon3} />
          <ServicesH2>Mediation</ServicesH2>
          <ServicesP>
            With our 20 years of experience in mediation, we will ensure a fair
            and justifiable arbitration for both parties.
          </ServicesP>
        </ServicesCard>
      </ServicesWrapper>
    </ServicesContainer>
  );
};

export default Services;
